<template>
  <v-app-bar
    v-scroll="onScroll"
    app
    elevate-on-scroll
    :color="!isScrolling ? 'transparent' : 'white'"
  >
    <v-img
      max-width="7%"
      src="/static/energetyka-wagon-logo.png"
    />
    <v-spacer />
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
        class="text-capitalize subtitle-1 mx-1"
        text
      >
        <span v-text="item.text" />
      </v-btn>
    </template>
    <v-menu v-else>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card
        color="primary"
        dark
      >
        <v-list color="transparent">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
          >
            <v-list-item-title v-text="item.text" />
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      isScrolling: false,
      items: [
        {
          to: '/',
          text: 'STRONA GŁÓWNA'
        },
        {
          to: '/o-firmie-energetyka-wagon/',
          text: 'O FIRMIE'
        },
        {
          to: '/sprzedaz-energii/',
          text: 'SPRZEDAŻ ENERGII'
        },
        {
          to: '/uslugi/',
          text: 'USŁUGI'
        },
        {
          to: '/klienci-energetyka-wagon/',
          text: 'KLIENCI'
        },
        {
          to: '/prace-badawcze/',
          text: 'PRACE BADAWCZE'
        },
        {
          to: '/kontakt-energetyka-wagon/',
          text: 'KONTAKT'
        }
      ]
    }),

    methods: {
      ...mapMutations(['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 20
      }
    }
  }
</script>
